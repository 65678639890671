<script>
import { mapState } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';

export default {
  mixins: [utilsMixin],
  components: { AffiliateConditionList },
  i18n: {
    messages: {
      fr: {
        ACCEPTED: 'confirmée',
        REJECTED: 'rejetée',
      },
    },
  },
  static: {
    form: {
      payout_request_amount: {
        type: 'number',
        column: 'is-5',
        label: 'Montant à régler',
        inputAttrs: {
          required: true,
          min: 1,
          step: 0.01,
        },
      },
      payout_invoice_url: {
        type: 'text',
        column: 'is-7',
        label: 'Lien vers la facture (https://unsite.fr)',
        inputAttrs: {
          maxlength: 512,
          pattern: 'https?://.+',
        },
        tooltip: `
          Pour encaisser des revenus issus de l'affiliation,
          vous êtes tenu.e d'éditer une facture depuis votre société
          ou votre micro-entreprise.
          Si vous n'avez pas de lien direct vers votre facture,
          vous pouvez l'héberger sur Google Drive ou Dropbox
          et communiquer ici le lien de partage du document.
        `,
        tooltipAttrs: {
          position: 'is-left',
          multilined: true,
        },
      },
      payout_info: {
        type: 'textarea',
        column: 'is-12',
        label: 'Remarques et/ou informations de règlement (Paypal, RIB…)',
        inputAttrs: {
          required: true,
          maxlength: 512,
        },
      },
    },
  },
  data: () => ({
    hasPayoutLoading: false,
    newPayout: {
      payout_request_amount: null,
      payout_info: '',
      payout_invoice_url: null,
    },
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
    ...mapState('auth', {
      affiliate: 'affiliateData',
    }),
  },
  methods: {
    askPayout() {
      if (this.hasPayoutLoading) {
        return;
      }

      this.hasPayoutLoading = true;
      const newPayout = { ...this.newPayout };

      if (newPayout.payout_invoice_url === '') {
        newPayout.payout_invoice_url = null;
      }

      this.$store.dispatch('auth/askAffiliatePayout', newPayout)
        .finally(() => (this.hasPayoutLoading = false));
    },
    showComment(payout) {
      this.$buefy.dialog.alert({
        message: payout.comment,
      });
    },
  },
};
</script>

<template>
  <div>
    <b-skeleton v-if="!affiliate" height="400" />
    <template v-else>
      <section>
        <h2 class="title is-4 is-custom">
          Conditions du programme d'affiliation
        </h2>

        <AffiliateConditionList class="mb-5" />

        <h2 class="title is-4 is-custom">
          Nouvelle demande de règlement
        </h2>

        <div v-if="affiliate.payout_request_at" class="box">
          <p>
            <strong>Règlement demandé</strong> :
            {{ affiliate.payout_request_amount | formatPrice }}
          </p>
          <p v-if="affiliate.payout_invoice_url" class="break-words">
            <strong>Lien de la facture</strong> :
            <a
              class="has-text-black is-custom tdecoration-underline"
              :href="affiliate.payout_invoice_url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ affiliate.payout_invoice_url }}
            </a>
          </p>
          <p>
            <strong>Commentaire</strong> :
            <br>
            <span class="wspance-preline">
              {{ affiliate.payout_info }}
            </span>
          </p>
          <hr>
          <p>
            Demande effectuée
            <b-tooltip>
              <template #content>
                {{ affiliate.payout_request_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
              </template>
              {{ affiliate.payout_request_at | momentFromUTC | moment('from') }}
            </b-tooltip>
          </p>
          <p>
            Dans l'attente du traitement de votre demande de règlement,
            vous ne pouvez pas en soumettre de nouvelle.
          </p>
        </div>
        <form v-else class="box" @submit.prevent="askPayout">
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in $options.static.form"
              :key="k"
              class="column mb-0"
              :class="field.column"
            >
              <GeneratorField
                :fieldName="k"
                :fieldDefinition="field"
                :formData.sync="newPayout"
              />
            </div>
            <div class="column is-12 has-text-right">
              <b-button
                native-type="submit"
                type="is-primary is-custom"
                :loading="hasPayoutLoading"
              >
                Demander un règlement
              </b-button>
            </div>
          </div>
        </form>
      </section>

      <section v-if="affiliate.payout_history.length > 0" class="mt-10">
        <h2 class="title is-4 is-custom">
          Historique des demandes de règlement
        </h2>

        <b-table class="box" :data="affiliate.payout_history">
          <b-table-column
            field="amount"
            label="Demande effectuée"
            v-slot="{ row }"
          >
            le {{ row.request_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </b-table-column>
          <b-table-column
            field="amount"
            label="Décision"
            v-slot="{ row }"
          >
            <p
              class="has-text-weight-bold"
              :class="{
                'has-text-success': row.decision === 'ACCEPTED',
                'has-text-danger': row.decision === 'REJECTED',
              }"
            >
              <span v-t="row.decision" />
              le {{ row.decision_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
            </p>
          </b-table-column>
          <b-table-column
            field="amount"
            label="Montant"
            numeric
            v-slot="{ row }"
          >
            {{ row.amount | formatPrice }}
          </b-table-column>
          <b-table-column label="Informations" v-slot="{ row }">
            <div>
              <p v-if="row.invoice_url">
                <a
                  class="has-text-black"
                  :href="row.invoice_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-icon icon="receipt" />
                  Voir la facture
                </a>
              </p>
              <p v-else>
                <b-icon icon="receipt" />
                Pas de facture
              </p>
              <p class="mt-1">
                <a class="has-text-black" @click.prevent="showComment(row)">
                  <b-icon icon="comment" />
                  Voir le commentaire
                </a>
              </p>
            </div>
          </b-table-column>
        </b-table>
      </section>
    </template>
  </div>
</template>
